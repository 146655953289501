import { MailOutline, LinkedIn, GitHub } from "@material-ui/icons";
import { CircularProgress } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import "../team/team.css";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Create } from "@material-ui/icons";
function User(props) {
  const isAuth = localStorage.getItem("ud") ? true : false;
  let udet = {};
  if (isAuth) {
    udet = JSON.parse(localStorage.getItem("ud"));
  }

  const [member, setMember] = useState();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const memberId = udet.uid;
      const apiUrl = `${process.env.REACT_APP_SERVER_URL}/team?id=${memberId}`;
      const response = await fetch(apiUrl);
      const data = await response.json();
      if (response.status === 200) {
        return data.data;
      } else {
        alert(`${data.error}`);
      }
    };
    fetchData().then((recieved) => {
      if (!recieved) {
        return;
      }
      setMember(recieved[0]);
      setLoading(false);
    });
  }, [props.match.params.memberId]);

  return (
    <>
      {loading && (
        <div className="loading">
          <div>
            <CircularProgress />
          </div>
        </div>
      )}
      {!loading && (
        <div className="user">
          <div className="userTitleContainer">
            <h1 className="userTitle">My Profile</h1>
          </div>
          <div className="userContainer">
            <div className="userShow">
              <div className="userShowTop">
                <img src={member.photo} alt="" className="userShowImg" />
                <div className="userShowTopTitle">
                  <span className="userShowName">{member.name}</span>
                  <span className="userShowDomain">{member.domain}</span>
                </div>
              </div>
              <div className="userShowBottom">
                {member.github && (
                  <>
                    <span className="userShowTitle">Github</span>
                    <div className="userShowInfo">
                      <GitHub className="userShowIcon" />
                      <span className="userShowInfoTitle">{member.github}</span>
                    </div>
                  </>
                )}

                <span className="userShowTitle">Contact Details</span>
                <div className="userShowInfo">
                  <MailOutline className="userShowIcon" />
                  <span className="userShowInfoTitle">{member.email}</span>
                </div>
                {member.linkedin && (
                  <>
                    <div className="userShowInfo">
                      <LinkedIn className="userShowIcon" />
                      <span className="userShowInfoTitle">
                        {member.linkedin}
                      </span>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      <div>
        <Link className="profileEdit" to={"/edit/profile/"}>
          <Create className="profile-action-btn" />
        </Link>
      </div>
    </>
  );
}
export default withRouter(User);
