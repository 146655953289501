import "./newMember.css";
import "../team/team.css";
import { Button, CircularProgress, Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { useForm } from "react-hook-form";
import { useState, forwardRef } from "react";
import Input from "../../components/input/Input";
import ImageUpload from "../../components/imageUpload/ImageUpload";
import SelectBox from "../../components/selectBox/SelectBox";
import DomainList from "../../assets/lists/domain";
import DefaultAvatar from "../../assets/user.png";

export default function NewUser() {
  const [imgFile, setImgFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [alertBar, setAlertBar] = useState({
    open: false,
    success: false,
    message: "",
  });
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
  });

  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertBar((cur) => ({ ...cur, open: false }));
  };

  const onSubmit = async (data) => {
    setLoading(true);
    console.log(data);
    let fd = new FormData();
    for (let item in data) {
      fd.append(item, data[item]);
    }
    const apiUrl = `${process.env.REACT_APP_SERVER_URL}/team/v2/new`;
    const response = await fetch(apiUrl, {
      method: "POST",
      body: fd,
    });
    const result = await response.json();
    if (response.status === 201) {
      reset({
        name: "",
        email: "",
        domain: "",
        github: "",
        linkedin: "",
        photo: "",
      });
      setImgFile(null);
      setLoading(false);
      setAlertBar({ open: true, success: true, message: "Member Added" });
    } else {
      setLoading(false);
      setAlertBar({ open: true, success: false, message: `${result.error}` });
    }
  };
  return (
    <>
      <div className="userUpdate">
        <h1>New Member</h1>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="userUpdateForm">
            <div className="userUpdateLeft">
              <Input
                type="text"
                name="name"
                label="Name"
                required
                errors={errors}
                control={control}
                rules={{ required: "Name is required" }}
              />
              <Input
                type="email"
                name="email"
                label="Email"
                required
                errors={errors}
                control={control}
                rules={{
                  required: "Email is required",
                  pattern: {
                    value: /^[0-9]{7,8}@kiit.ac.in$/,
                    message: "Enter a KIIT email",
                  },
                }}
              />
              <SelectBox
                name={"domain"}
                label={"Domain"}
                control={control}
                options={DomainList}
                errors={errors}
                rules={{ required: "Domain is required" }}
              />
              <Input
                type="text"
                name="github"
                label="Github Link"
                control={control}
                errors={errors}
              />
              <Input
                type="text"
                name="linkedin"
                label="LinkedIn Link"
                control={control}
                errors={errors}
              />
            </div>
            <div className="userUpdateRight">
              <ImageUpload
                mode="new"
                name="photo"
                control={control}
                imgSrc={imgFile}
                setimgSrc={setImgFile}
                defaultAvatar={DefaultAvatar}
                errors={errors}
                rules={{
                  required: "Image is required",
                }}
              />
              <Button
                variant="contained"
                disableElevation
                type="submit"
                disabled={loading}
                style={{
                  backgroundColor: "#4169E1",
                  color: "white",
                  fontWeight: 600,
                  fontSize: "1.1rem",
                }}
              >
                {loading && <CircularProgress />}
                {!loading && "Add"}
              </Button>
            </div>
          </div>
        </form>
      </div>
      <Snackbar
        open={alertBar.open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          severity={alertBar.success ? "success" : "error"}
          onClose={handleClose}
        >
          {alertBar.message}
        </Alert>
      </Snackbar>
    </>
  );
}
