import Topbar from "./components/topbar/Topbar";
import "./App.css";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Login from "./pages/login/Login";

import DefaultContainer from "./components/defaultContainer/DefaultContainer";

function App() {
  return (
    <Router>
      <Topbar />
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route component={DefaultContainer} />
      </Switch>
    </Router>
  );
}

export default App;
