import React from "react";
import "./topbar.css";
import logo from "../../assets/elabs_logo.png";
import { Link } from "react-router-dom";
import { ExitToApp } from "@material-ui/icons";

export default function Topbar() {
  const isAuth = localStorage.getItem("ud") ? true : false;
  let udet = {};
  if (isAuth) {
    udet = JSON.parse(localStorage.getItem("ud"));
  }
  const onLogout = async () => {
    const token = JSON.parse(localStorage.getItem("ud")).utoken;
    const apiURL = `${process.env.REACT_APP_SERVER_URL}/auth/admin/logout`;
    const response = await fetch(apiURL, {
      headers: {
        "auth-token": token,
      },
    });
    if (response.status === 200) {
      localStorage.removeItem("ud");
      window.location.assign("/login");
    } else {
      alert("Error. Try Again!!");
    }
  };

  return (
    <div className="topbar">
      <div className="topbarWrapper">
        <div className="topLeft">
          <div className="top-logo">
            <Link to="/" className="link">
              <img src={logo} alt="" className="topAvatar" />
            </Link>
          </div>
          <div className="top-text">
            <Link to="/" className="link">
              E LABS
            </Link>
          </div>
        </div>
        <div className="topRight">
          {isAuth && (
            <>
              <Link className="link" to={"/myprofile/"}>
                <div className="top-details">
                  <div>
                    <img
                      className="topAvatar"
                      src={udet.uphoto}
                      alt={udet.uid}
                    />
                  </div>
                  <div className="top-user">
                    <div className="top-name">{udet.uname}</div>
                    <div className="top-domain">{udet.udomain}</div>
                  </div>
                </div>
              </Link>
              <div onClick={onLogout} title="Logout">
                <ExitToApp
                  className="top-logout"
                  style={{ width: "1.8rem", height: "1.8rem" }}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
