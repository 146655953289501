import React from "react";
import { Publish, Clear, Update } from "@material-ui/icons";
import { FormHelperText } from "@material-ui/core";
import { useController } from "react-hook-form";
import "./imageUpload.css";
import DefaultAvatar from "../../assets/user.png";

function ImageUpload({
  show = true,
  imgHeight = 200,
  imgWidth = 200,
  mode = "new",
  control,
  label,
  name,
  imgSrc,
  setimgSrc,
  defaultAvatar,
  rules = {},
  errors = {},
}) {
  const { field } = useController({
    control,
    name,
    rules,
    defaultValue: mode === "update" ? `${defaultAvatar}` : "",
  });
  return (
    <>
      <div className="img-upload">
        <div className="img-upload-top">
          <img
            className="img-preview"
            style={{ width: imgWidth, height: imgHeight }}
            src={imgSrc ? URL.createObjectURL(imgSrc) : defaultAvatar}
            alt={"img-" + name}
          />
          <label htmlFor={name}>
            <Publish className="img-push-button" />
          </label>
          <input
            type="file"
            id={name}
            style={{ display: show ? "none" : "block" }}
            value={""}
            onChange={(e) => {
              setimgSrc(e.target.files[0]);
              field.onChange(e.target.files[0]);
            }}
          />
          <span>
            <Clear
              className="img-push-button"
              onClick={() => {
                setimgSrc(null);
                field.onChange(mode === "update" ? defaultAvatar : null);
              }}
            />
          </span>
        </div>
        <div className="img-upload-bottom">
          {errors[name] && (
            <FormHelperText
              error={errors[name] ? true : false}
              style={{ fontSize: "1.1rem" }}
            >
              {errors[name].message}
            </FormHelperText>
          )}
        </div>
      </div>
    </>
  );
}

export default ImageUpload;
