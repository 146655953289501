import "./teamList.css";
import { DataGrid } from "@material-ui/data-grid";
import { DeleteOutline, Visibility, Create } from "@material-ui/icons";
import MuiAlert from "@material-ui/lab/Alert";
import { Snackbar } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useState, useEffect, forwardRef } from "react";
import ConfirmationDialog from "../../components/confirmationDialog/ConfirmationDialog";

export default function UserList() {
  // eslint-disable-next-line no-unused-vars
  const [apiUrl, setApiUrl] = useState(
    `${process.env.REACT_APP_SERVER_URL}/team`
  );
  const [data, setData] = useState([]);
  const [delStatus, setDelStatus] = useState(false);
  const [delDialog, setDelDialog] = useState(false);
  const [name, setName] = useState("");
  const [id, setId] = useState("");
  const [alertBar, setAlertBar] = useState({
    open: false,
    success: false,
    message: "",
  });

  let isAdmin = false;
  if (localStorage.getItem("ud")) {
    isAdmin = JSON.parse(localStorage.getItem("ud")).uadmin;
  }

  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(apiUrl);
      const returnedData = await response.json();
      if (response.status === 200) {
        return returnedData;
      } else {
        alert(returnedData.error);
      }
    };
    fetchData().then((allData) => {
      setData(allData.data);
    });
  }, [apiUrl]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertBar((cur) => ({ ...cur, open: false }));
  };

  const handleDelete = async () => {
    handleDelonCancel();
    const url = `${process.env.REACT_APP_SERVER_URL}/team/v2/${id}`;
    const response = await fetch(url, {
      method: "DELETE",
    });
    const result = await response.json();
    if (response.status === 200) {
      setData(data.filter((item) => item._id !== id));
      setAlertBar({
        open: true,
        success: true,
        message: `${name} removed successfully`,
      });
    } else if (response.status === 400) {
      setAlertBar({
        open: true,
        success: false,
        message: `FAILURE: ${name} cannot be removed`,
      });
    } else {
      setAlertBar({
        open: true,
        success: false,
        message: `${result.error}`,
      });
    }
  };
  const handleDelonCancel = () => {
    setDelDialog(false);
    setDelStatus(false);
  };

  const columns = [
    {
      field: "name",
      headerName: "Members",
      flex: 1,
      renderCell: (params) => {
        return (
          <div className="userListUser">
            <img className="userListImg" src={params.row.photo} alt="" />
            {params.row.name}
          </div>
        );
      },
    },
    { field: "email", headerName: "Email", flex: 1 },
    {
      field: "action",
      headerName: "Action",
      width: 300,
      renderCell: (params) => {
        return (
          <>
            <div className="all-actions">
              <div>
                <Link
                  to={"/team/view/" + params.row._id}
                  className="link userListView"
                >
                  <Visibility className="user-action-btn" />
                </Link>
              </div>
              {isAdmin && (
                <>
                  <div>
                    <Link
                      to={"/team/edit/" + params.row._id}
                      className="link userListEdit"
                    >
                      <Create className="user-action-btn" />
                    </Link>
                  </div>
                  <div>
                    <DeleteOutline
                      className="userListDelete"
                      onClick={() => {
                        setDelStatus(true);
                        setName(params.row.name);
                        setId(params.row._id);
                      }}
                    />
                  </div>
                </>
              )}
            </div>
          </>
        );
      },
    },
  ];

  return (
    <>
      <div className="userList">
        <DataGrid
          rows={data}
          disableSelectionOnClickx
          disableSelectionOnClick
          columns={columns}
          autoPageSize
          pagination
          rowHeight={"60"}
          getRowId={(row) => row._id}
          loading={data.length === 0}
          style={{ fontSize: "1rem" }}
        />
        {setDelDialog && (
          <ConfirmationDialog
            type="delete"
            title="Delete Member"
            message={"Do you want to remove " + name + " from the team?"}
            mainButtonText={"Delete"}
            status={delStatus}
            onCancel={handleDelonCancel}
            onSuccess={handleDelete}
          />
        )}
      </div>
      <Snackbar
        open={alertBar.open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          severity={alertBar.success ? "success" : "error"}
          onClose={handleClose}
        >
          {alertBar.message}
        </Alert>
      </Snackbar>
    </>
  );
}
