import Input from "../../components/input/Input";
import React, { useState, forwardRef } from "react";
import { useForm } from "react-hook-form";
import DateTime from "../../components/datetimepicker/DateTime";
import { Button, CircularProgress, Grid, Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import EventDomainList from "../../assets/lists/eventDomain";
import SelectBox from "../../components/selectBox/SelectBox";
import "./webinar.css";

function NewWebinar() {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ mode: "onBlur" });
  const [loading, setLoading] = useState(false);
  const [alertBar, setAlertBar] = useState({
    open: false,
    success: false,
    message: "",
  });
  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertBar((cur) => ({ ...cur, open: false }));
  };
  const onSubmit = async (data) => {
    setLoading(true);
    console.log(data);
    const apiUrl = `${process.env.REACT_APP_SERVER_URL}/webinar/new`;
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(data),
    });
    const result = await response.json();
    if (response.status === 201) {
      reset({
        name: "",
        domain: "",
        when: null,
        regStart: null,
        regEnd: null,
        desc: "",
      });
      setLoading(false);
      setAlertBar({ open: true, success: true, message: "Event Added" });
    } else {
      setLoading(false);
      setAlertBar({ open: true, success: false, message: `${result.error}` });
    }
  };

  return (
    <>
      <div className="webinar-container">
        <h1>New Webinar</h1>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container>
            <Grid item sm={6} xs={12}>
              <Input
                name={"name"}
                label={"Event Name"}
                control={control}
                errors={errors}
                rules={{ required: "Event name is required" }}
              />
              <SelectBox
                name="domain"
                label="Domain"
                control={control}
                errors={errors}
                rules={{ required: "Event Domain is required" }}
                options={EventDomainList}
              />
              <DateTime
                name="when"
                label="Event Time and Date"
                control={control}
                errors={errors}
                rules={{ required: "Event time is required" }}
              />
              <DateTime
                name="regStart"
                label="Form Opens at"
                control={control}
                errors={errors}
                rules={{ required: "Registration start time is required" }}
              />
              <DateTime
                name="regEnd"
                label="Form Closes at"
                control={control}
                errors={errors}
                rules={{ required: "Registration close time is required" }}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <Input
                name={"desc"}
                label="Description"
                control={control}
                multiline
                minRows={4}
              />
            </Grid>
          </Grid>
          <Button
            variant="contained"
            disableElevation
            type="submit"
            disabled={loading}
            style={{
              backgroundColor: "#4169E1",
              color: "white",
              fontWeight: 600,
              fontSize: "1.1rem",
              width: "30vw",
              marginTop: "2rem",
            }}
          >
            {loading && <CircularProgress size="1.6rem" color="#fff" />}
            {!loading && "Add"}
          </Button>
        </form>
      </div>
      <Snackbar
        open={alertBar.open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          severity={alertBar.success ? "success" : "error"}
          onClose={handleClose}
        >
          {alertBar.message}
        </Alert>
      </Snackbar>
    </>
  );
}

export default NewWebinar;
