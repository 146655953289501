import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import { Add } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { io } from "socket.io-client";
import validator from "validator";
import Input from "../../components/input/Input";
import "./urlshort.css";
function UrlShort() {
  const [allUrl, setAllUrl] = useState([]);
  const [createModal, setCreateModal] = useState(false);
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();
  const [socket] = useState(io(`${process.env.REACT_APP_SERVER_URL}`));
  const [query, setQuery] = useState("");
  useEffect(() => {
    socket.on("connect", () => {
      console.log(`Connected as ${socket.id}`);
      socket.on("new-url", (data) => {
        setAllUrl((cur) => [...cur, data]);
      });
    });
  }, [socket]);

  useEffect(() => {
    const fetchData = async () => {
      const apiUrl = `${process.env.REACT_APP_SERVER_URL}/url/${query}`;
      const res = await fetch(apiUrl);
      const data = await res.json();
      if (res.status === 200) {
        return data;
      } else {
        alert(`${data.error}`);
      }
    };
    fetchData().then((data) => {
      if (data) {
        setAllUrl(data.urls);
      }
    });
  }, [query]);

  const cancelModal = () => {
    setCreateModal(false);
    reset({
      alias: "",
      link: "",
    });
  };
  const onSubmit = (data) => {
    console.log(data);
  };
  const columns = [
    {
      field: "alias",
      headerName: "Alias",
      width: 200,
    },
    {
      field: "link",
      headerName: "Link",
      width: 400,
      renderCell: (params) => {
        return (
          <a
            className="link"
            href={params.row.link}
            target="_blank"
            rel="noreferrer"
          >
            {params.row.link}
          </a>
        );
      },
    },
    {
      field: "shortenedLink",
      headerName: "Short Link",
      width: 300,
      renderCell: (params) => {
        return (
          <a
            className="link"
            href={`https://elabskiit.me/@${params.row.alias}`}
            target="_blank"
            rel="noreferrer"
          >
            {" "}
            {`https://elabskiit.me/@${params.row.alias}`}
          </a>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 200,
    },
  ];
  return (
    <>
      <div className="url-container">
        <div className="util-bar">
          <div className="url-heading">URL Shortener</div>
          <div className="btn">
            <Button
              variant="contained"
              disableElevation
              startIcon={<Add />}
              id={"url-createbtn"}
              onClick={() => {
                setCreateModal(true);
              }}
            >
              Create
            </Button>
          </div>
        </div>
        <div className="all-urls">
          <DataGrid
            rows={allUrl}
            getRowId={(row) => row._id}
            columns={columns}
            disableSelectionOnClickx
            disableSelectionOnClick
            loading={allUrl.length === 0}
          />
        </div>
      </div>
      <Dialog
        open={createModal}
        onClose={cancelModal}
        maxWidth={"sm"}
        fullWidth
      >
        <DialogTitle>🔗 Create new link</DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <DialogContentText>
              <div className="url-form">
                <Input
                  control={control}
                  label="Alias"
                  name="alias"
                  errors={errors}
                  variant="outlined"
                  rules={{
                    validate: (val) => {
                      return !/\//.test(val) || `Do not use '/' in the alias`;
                    },
                  }}
                />
                <Input
                  control={control}
                  label="Link"
                  name="link"
                  errors={errors}
                  variant="outlined"
                  rules={{
                    required: "URL is required",
                    validate: (val) => {
                      return (
                        (val !== "" &&
                          validator.isURL(val, {
                            require_protocol: true,
                            allow_fragments: true,
                          })) ||
                        "Provide a valid URL"
                      );
                    },
                  }}
                />
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={cancelModal}
              style={{
                fontSize: "1rem",
                fontWeight: "400",
                color: "#4f4f4f",
              }}
              variant="text"
              disableElevation
            >
              Cancel
            </Button>
            <Button
              type="submit"
              style={{
                fontSize: "1rem",
                fontWeight: "400",
                color: "#4f4f4f",
              }}
              variant="contained"
              disableElevation
            >
              Create
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}

export default UrlShort;
