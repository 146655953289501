import React, { useState, useEffect, forwardRef } from "react";
import { withRouter } from "react-router-dom";
import Input from "../../components/input/Input";
import { useForm } from "react-hook-form";
import DateTime from "../../components/datetimepicker/DateTime";
import { Button, CircularProgress, Grid, Snackbar } from "@material-ui/core";
import EventDomainList from "../../assets/lists/eventDomain";
import SelectBox from "../../components/selectBox/SelectBox";
import MuiAlert from "@material-ui/lab/Alert";
import "../newWebinar/webinar.css";
function EditWebinar(props) {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onBlur" });
  const [eventDet, setEventDet] = useState();
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [alertBar, setAlertBar] = useState({
    open: false,
    success: false,
    message: "",
  });
  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertBar((cur) => ({ ...cur, open: false }));
  };
  useEffect(() => {
    const fetchData = async () => {
      setPageLoading(true);
      const eventId = props.match.params.eventId;
      const apiUrl = `${process.env.REACT_APP_SERVER_URL}/webinar?id=${eventId}`;
      const response = await fetch(apiUrl);
      const data = await response.json();
      if (response.status === 200) {
        return data.data;
      } else {
        setAlertBar({ open: true, success: false, message: data.error });
        return;
      }
    };
    fetchData().then((recieved) => {
      if (!recieved) {
        return;
      }
      setEventDet(recieved[0]);
      setPageLoading(false);
    });
  }, [props.match.params.eventId]);

  const onSubmit = async (data) => {
    setLoading(true);
    console.log(data);
    let diffData = {};
    for (let item in data) {
      if (data[item] !== eventDet[item]) {
        diffData[item] = data[item];
      }
    }
    console.log("diff", diffData);
    console.log(eventDet._id);
    const apiUrl = `${process.env.REACT_APP_SERVER_URL}/webinar/${eventDet._id}`;
    const response = await fetch(apiUrl, {
      method: "PATCH",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(data),
    });
    const result = await response.json();
    if (response.status === 201) {
      setAlertBar({ open: true, success: true, message: "Event Updated" });
    } else {
      setAlertBar({ open: true, success: false, message: `${result.error}` });
    }
    setLoading(false);
  };

  return (
    <>
      {pageLoading && (
        <div className="loading">
          <div>
            <CircularProgress />
          </div>
        </div>
      )}
      {!pageLoading && (
        <>
          <div className="webinar-container">
            <h1>Edit Event</h1>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container>
                <Grid item sm={6} xs={12}>
                  <Input
                    name={"name"}
                    label={"Event Name"}
                    control={control}
                    errors={errors}
                    rules={{ required: "Event name is required" }}
                    defaultValue={eventDet.name}
                  />
                  <SelectBox
                    name="domain"
                    label="Domain"
                    control={control}
                    errors={errors}
                    rules={{ required: "Event Domain is required" }}
                    options={EventDomainList}
                    defaultValue={eventDet.domain}
                  />
                  <DateTime
                    name="when"
                    label="Event Time and Date"
                    control={control}
                    errors={errors}
                    rules={{ required: "Event time is required" }}
                    defaultValue={eventDet.when}
                  />
                  <DateTime
                    name="regStart"
                    label="Form Opens at"
                    control={control}
                    errors={errors}
                    rules={{ required: "Registration start time is required" }}
                    defaultValue={eventDet.regStart}
                  />
                  <DateTime
                    name="regEnd"
                    label="Form Closes at"
                    control={control}
                    errors={errors}
                    rules={{ required: "Registration close time is required" }}
                    defaultValue={eventDet.regEnd}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Input
                    name={"desc"}
                    label="Description"
                    control={control}
                    multiline
                    minRows={4}
                    defaultValue={eventDet.desc}
                  />
                </Grid>
              </Grid>
              <Button
                variant="contained"
                disableElevation
                type="submit"
                disabled={loading}
                style={{
                  backgroundColor: "#4169E1",
                  color: "white",
                  fontWeight: 600,
                  fontSize: "1.1rem",
                  width: "30vw",
                  marginTop: "2rem",
                }}
              >
                {loading && <CircularProgress size="1.6rem" color="#fff" />}
                {!loading && "Update"}
              </Button>
            </form>
          </div>
        </>
      )}
      <Snackbar
        open={alertBar.open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          severity={alertBar.success ? "success" : "error"}
          onClose={handleClose}
        >
          {alertBar.message}
        </Alert>
      </Snackbar>
    </>
  );
}

export default withRouter(EditWebinar);
