import React from "react";
import MuiAlert from "@material-ui/lab/Alert";
import { useForm } from "react-hook-form";
import { CircularProgress, Button, Snackbar } from "@material-ui/core";
import { useState, useEffect, forwardRef } from "react";
import { withRouter } from "react-router-dom";
import Switch from "react-switch";
import "../team/team.css";
import Input from "../../components/input/Input";
import DomainList from "../../assets/lists/domain";
import SelectBox from "../../components/selectBox/SelectBox";
import ImageUpload from "../../components/imageUpload/ImageUpload";
import SliderSwitch from "../../components/sliderSwitch/SliderSwitch";

function EditMember(props) { 
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onBlur" });
  const [member, setMember] = useState();
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [imgFile, setImgFile] = useState(null);
  const [alertBar, setAlertBar] = useState({
    open: false,
    success: false,
    message: "",
  });

  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertBar((cur) => ({ ...cur, open: false }));
  };

  useEffect(() => {
    const fetchData = async () => {
      setPageLoading(true);
      const memberId = props.match.params.memberId;
      const apiUrl = `${process.env.REACT_APP_SERVER_URL}/team?id=${memberId}`;
      const response = await fetch(apiUrl);
      const data = await response.json();
      if (response.status === 200) {
        return data.data;
      } else {
        setAlertBar({ open: true, success: false, message: data.error });
        return;
      }
    };
    fetchData().then((recieved) => {
      if (!recieved) {
        return;
      }
      setMember(recieved[0]);
      setPageLoading(false);
    });
  }, [props.match.params.memberId]);

  const onSubmit = async (data) => {
    setLoading(true);
    console.log(data);
    let diffData = new FormData();
    for (let item in data) {
      if (data[item] !== member[item]) {
        diffData.append(item, data[item]);
      }
    }
    const apiUrl = `${process.env.REACT_APP_SERVER_URL}/team/v2/${member._id}`;
    const response = await fetch(apiUrl, {
      method: "PATCH",
      body: diffData,
    });
    const result = await response.json();
    if (response.status === 201) {
      setLoading(false);
      setAlertBar({ open: true, success: true, message: "Member Updated" });
    } else {
      setLoading(false);
      setAlertBar({ open: true, success: false, message: `${result.error}` });
    }
  };
  return (
    <>
      {pageLoading && (
        <div className="loading">
          <div>
            <CircularProgress />
          </div>
        </div>
      )}
      {!pageLoading && (
        <>
          <div className="userUpdate">
            <h1>Edit Member</h1>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="userUpdateForm">
                <div className="userUpdateLeft">
                  <Input
                    type="text"
                    name="name"
                    label="Name"
                    defaultValue={member.name}
                    required
                    errors={errors}
                    control={control}
                    rules={{ required: "Name is required" }}
                  />
                  <Input
                    type="email"
                    name="email"
                    label="Email"
                    defaultValue={member.email}
                    required
                    errors={errors}
                    control={control}
                    rules={{
                      required: "Email is required",
                      pattern: {
                        value: /^[0-9]{7,8}@kiit.ac.in$/,
                        message: "Enter a KIIT email",
                      },
                    }}
                  />
                  <SelectBox
                    name={"domain"}
                    label={"Domain"}
                    defaultValue={member.domain}
                    control={control}
                    options={DomainList}
                    errors={errors}
                    rules={{ required: "Domain is required" }}
                  />
                  <Input
                    type="text"
                    name="github"
                    label="Github Link"
                    defaultValue={member.github ? member.github : ""}
                    control={control}
                    errors={errors}
                  />
                  <Input
                    type="text"
                    name="linkedin"
                    label="LinkedIn Link"
                    defaultValue={member.linkedin ? member.linkedin : ""}
                    control={control}
                    errors={errors}
                  />
                  <SliderSwitch
                    name="isActive"
                    label="Member status"
                    control={control}
                    defaultValue={member.isActive}
                  />
                  <SliderSwitch
                    name="isAdmin"
                    label="Admin privileges"
                    control={control}
                    defaultValue={member.isAdmin}
                  />
                </div>
                <div className="userUpdateRight">
                  <ImageUpload
                    mode="update"
                    name="photo"
                    control={control}
                    imgSrc={imgFile}
                    setimgSrc={setImgFile}
                    defaultAvatar={member.photo}
                    errors={errors}
                  />
                  <Button
                    variant="contained"
                    disableElevation
                    type="submit"
                    disabled={loading}
                    style={{
                      backgroundColor: "#4169E1",
                      color: "white",
                      fontWeight: 600,
                      fontSize: "1.1rem",
                    }}
                  >
                    {loading && (
                      <CircularProgress color={"#fff"} size="1.8rem" />
                    )}
                    {!loading && "Update"}
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </>
      )}
      <Snackbar
        open={alertBar.open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          severity={alertBar.success ? "success" : "error"}
          onClose={handleClose}
        >
          {alertBar.message}
        </Alert>
      </Snackbar>
    </>
  );
}

export default withRouter(EditMember);
