import React from "react";
import Sidebar from "../sidebar/Sidebar";
import { Switch, Route } from "react-router-dom";
import Home from "../../pages/home/Home";
import TeamList from "../../pages/teamList/TeamList";
import Team from "../../pages/team/Team";
import NewMember from "../../pages/newMember/NewMember";
import EventList from "../../pages/eventList/EventList";
import EditMember from "../../pages/editMember/EditMember";
import ProtectedRoute from "../ProtectedRoute";
import NewWebinar from "../../pages/newWebinar/NewWebinar";
import EditWebinar from "../../pages/editWebinar/EditWebinar";
import Webinar from "../../pages/webinar/Webinar";
import Profile from "../../pages/Profile/Profile";
import ViewPage from "../../pages/Profile/ViewPage";
import UrlShort from "../../pages/urlShort/UrlShort";
function DefaultContainer() {
  return (
    <>
      <div className="container">
        <Sidebar />
        <Switch>
          <ProtectedRoute exact path="/" component={Home} />
          <ProtectedRoute exact path="/team" component={TeamList} />
          <ProtectedRoute
            path="/team/edit/:memberId"
            component={EditMember}
            adminProtect
          />
          <ProtectedRoute path="/team/view/:memberId" component={Team} />
          <ProtectedRoute path="/team/new" component={NewMember} adminProtect />
          <ProtectedRoute path="/event/new" component={NewWebinar} />
          <ProtectedRoute path="/event/edit/:eventId" component={EditWebinar} />
          <ProtectedRoute path="/event/view/:eventId" component={Webinar} />
          <ProtectedRoute path="/events" component={EventList} />
          <ProtectedRoute path="/edit/profile" component={Profile} />
          <ProtectedRoute path="/myprofile" component={ViewPage} />
          <ProtectedRoute path="/tools/urlshort" component={UrlShort} />
        </Switch>
      </div>
    </>
  );
}

export default DefaultContainer;
