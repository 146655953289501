import React from "react";
import { Route, Redirect } from "react-router-dom";

function ProtectedRoute({
  component: Component,
  adminProtect = false,
  ...rest
}) {
  const isAuth = localStorage.getItem("ud") ? true : false;
  const isAdmin = isAuth
    ? JSON.parse(localStorage.getItem("ud")).uadmin
    : false;
  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuth) {
          if ((adminProtect && isAdmin) || !adminProtect) {
            return <Component />;
          } else {
            return (
              <Redirect
                to={{ pathname: "/", state: { from: props.location } }}
              />
            );
          }
        } else {
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          );
        }
      }}
    />
  );
}

export default ProtectedRoute;
