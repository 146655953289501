import {
  Chip,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Menu,
  MenuItem,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import sourceList from "../../assets/lists/sourceList";
import branchList from "../../assets/lists/branchList";
import { Doughnut } from "react-chartjs-2";
import { DataGrid } from "@material-ui/data-grid";
import { Clear, Done, KeyboardArrowDown } from "@material-ui/icons";
import { Rating } from "@material-ui/lab";
import { io } from "socket.io-client";

function Webinar(props) {
  const [socket] = useState(io(`${process.env.REACT_APP_SERVER_URL}`));
  const [eventDet, setEventDet] = useState({});
  const [eventReg, setEventReg] = useState([]);
  const [regYear, setregYear] = useState([]);
  const [regSource, setRegSource] = useState([]);
  const [prior, setPrior] = useState([]);
  const [branch, setBranch] = useState([]);
  const [emails, setEmails] = useState([]);
  const [feedback, setFeedback] = useState({});
  const [openEmailDialog, setOpenEmailDialog] = useState(false);
  const [openCertDialog, setOpenCertDialog] = useState(false);
  const [openFeedbackDialog, setFeedbackDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    socket.on("connect", () => {
      console.log(`Connected as ${socket.id}`);
      socket.emit("join-webinar", props.match.params.eventId);
      socket.on("new-registration", (data) => {
        setEventReg((cur) => [...cur, data]);
      });
    });
  }, [props.match.params.eventId, socket]);

  useEffect(() => {
    setEmails(() => eventReg.map((each) => each.email));
  }, [eventReg]);

  useEffect(() => {
    const fetchEvent = async () => {
      const eventId = props.match.params.eventId;
      const apiUrl = `${process.env.REACT_APP_SERVER_URL}/webinar?id=${eventId}`;
      const response = await fetch(apiUrl);
      const result = await response.json();
      if (response.status === 200) {
        return result.data[0];
      } else {
        alert(`${result.error}`);
      }
    };
    const fetchReg = async () => {
      const eventId = props.match.params.eventId;
      const apiUrl = `${process.env.REACT_APP_SERVER_URL}/registration/webinar/${eventId}`;
      const response = await fetch(apiUrl);
      const result = await response.json();
      if (response.status === 200) {
        return result.data;
      } else {
        alert(`${result.errors}`);
      }
    };
    fetchEvent().then((event) => {
      if (event) {
        setEventDet(event);
      }
    });
    fetchReg().then((regs) => {
      if (regs) {
        //setEventReg((cur) => [...cur, ...regs]);
        setEventReg([...regs]);
      }
    });
  }, [props.match.params.eventId]);
  useEffect(() => {
    setregYear((cur) => [
      {
        year: "1st",
        number: eventReg.filter((each) => each.year === "1st").length,
      },
      {
        year: "2nd",
        number: eventReg.filter((each) => each.year === "2nd").length,
      },
      {
        year: "3rd",
        number: eventReg.filter((each) => each.year === "3rd").length,
      },
      {
        year: "4th",
        number: eventReg.filter((each) => each.year === "4th").length,
      },
    ]);
  }, [eventReg]);

  useEffect(() => {
    let regS = [];
    sourceList.forEach((each) => {
      regS.push({
        source: each,
        number: eventReg.filter((eachR) => eachR.source === each).length,
      });
    });
    setRegSource(regS);
  }, [eventReg]);

  useEffect(() => {
    let branchS = [];
    branchList.forEach((each) => {
      branchS.push({
        branch: each,
        number: eventReg.filter((eachR) => eachR.branch === each).length,
      });
    });
    setBranch(branchS);
  }, [eventReg]);

  useEffect(() => {
    setPrior(() => [
      {
        type: "Yes",
        number: eventReg.filter((each) => each.priorKnowledge === true).length,
      },
      {
        type: "No",
        number: eventReg.filter((each) => each.priorKnowledge === false).length,
      },
    ]);
  }, [eventReg]);

  const getRandomColor = () => {
    var letters = "0123456789ABCDEF";
    var color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const onCancel = () => {
    setOpenEmailDialog(false);
  };

  const onCertCancel = () => {
    setOpenCertDialog(false);
  };

  const onFeedbackCancel = () => {
    setFeedbackDialog(false);
  };

  const handleMenu = () => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleGenCertWebinar = async () => {
    setLoading(true);
    const apiUrl = `${process.env.REACT_APP_SERVER_URL}/certificate/webinar/${eventDet._id}`;
    const res = await fetch(apiUrl);
    const result = await res.json();
    let validCount = 0;
    if (res.status === 200) {
      for (let index in result.status) {
        let eachStatus = result.status[index];
        if (!eachStatus.updateStatus) {
          continue;
        }
        let findIndex = eventReg.findIndex(
          (each) => each._id === eachStatus.regId
        );
        validCount += 1;
        setEventReg((cur) => {
          cur[findIndex].certificate = true;
          cur[findIndex].certificateUrl = eachStatus.url;
          return cur;
        });
      }
      alert(
        `${validCount} certificates issued successfully out of ${result.status.length}`
      );
    } else {
      alert(res.error);
    }
    setLoading(false);
    setAnchorEl(null);
  };
  const handleCertEmailing = async () => {
    setLoading(true);
    const apiUrl = `${process.env.REACT_APP_SERVER_URL}/certificate/email/webinar/${eventDet._id}`;
    const response = await fetch(apiUrl);
    const result = await response.json();
    let emailSentCount = 0;
    let emailRejected = 0;
    if (response.status === 200) {
      for (let index in result.status) {
        let eachStatus = result.status[index];
        if (!eachStatus.status) {
          continue;
        } else if (eachStatus.emailRes.rejected.length > 0) {
          emailRejected += 1;
        } else {
          let findIndex = eventReg.findIndex(
            (each) => each._id === eachStatus.id
          );
          emailSentCount += 1;
          setEventReg((cur) => {
            cur[findIndex].certificateEmail = true;
            return cur;
          });
        }
      }
      alert(
        `Emails Sent : ${emailSentCount}\nEmail Rejected: ${emailRejected}\nTotal Requested: ${result.status.length}`
      );
    } else {
      console.log(result);
    }
    setLoading(true);
    setAnchorEl(null);
  };
  useEffect(() => {
    console.log(eventReg);
  }, [eventReg]);
  const columns = [
    { field: "name", headerName: "Name", width: 250 },
    { field: "email", headerName: "Email", width: 200 },
    { field: "year", headerName: "Year", width: 150 },
    { field: "branch", headerName: "Branch", width: 150 },
    { field: "source", headerName: "Source", width: 150 },
    { field: "mobile", headerName: "Mobile", width: 200 },
    { field: "whatsapp", headerName: "Whatsapp", width: 200 },
    {
      field: "priorKnowledge",
      headerName: "Knowledge",
      width: 150,
      renderCell: (params) => {
        return (
          <>
            <Chip
              avatar={
                params.row.priorKnowledge ? (
                  <Done style={{ color: "#fff" }} />
                ) : (
                  <Clear style={{ color: "#fff" }} />
                )
              }
              label={params.row.priorKnowledge ? "Yes" : "No"}
              style={{
                backgroundColor: params.row.priorKnowledge
                  ? "#43aa8b"
                  : "#ae2012",
                color: "white",
              }}
            />
          </>
        );
      },
    },
    {
      field: "confirmation",
      headerName: "Confirmation Email",
      width: 200,
      renderCell: (params) => {
        return (
          <>
            <Chip
              label={params.row.confirmation ? "Sent" : "Not Sent"}
              style={{
                backgroundColor: params.row.confirmation
                  ? "#43aa8b"
                  : "#ae2012",
                color: "white",
              }}
            />
          </>
        );
      },
    },
    {
      field: "attendance",
      headerName: "Attendance",
      width: 150,
      renderCell: (params) => {
        return (
          <>
            <Chip
              label={params.row.attendance ? "Present" : "Absent"}
              style={{
                backgroundColor: params.row.attendance ? "#43aa8b" : "#ae2012",
                color: "white",
              }}
            />
          </>
        );
      },
    },
    {
      field: "certificate",
      headerName: "Certificate Generated",
      width: 200,
      renderCell: (params) => {
        return (
          <>
            <Chip
              avatar={
                params.row.certificate ? (
                  <Done style={{ color: "#fff" }} />
                ) : (
                  <Clear style={{ color: "#fff" }} />
                )
              }
              label={params.row.certificate ? "Yes" : "No"}
              style={{
                backgroundColor: params.row.certificate ? "#43aa8b" : "#ae2012",
                color: "white",
              }}
            />
          </>
        );
      },
    },
    {
      field: "certificateEmail",
      headerName: "Certificate Email",
      width: 200,
      renderCell: (params) => {
        return (
          <>
            <Chip
              avatar={
                params.row.certificateEmail ? (
                  <Done style={{ color: "#fff" }} />
                ) : (
                  <Clear style={{ color: "#fff" }} />
                )
              }
              label={params.row.certificateEmail ? "Sent" : "Not Sent"}
              style={{
                backgroundColor: params.row.certificateEmail
                  ? "#43aa8b"
                  : "#ae2012",
                color: "white",
              }}
            />
          </>
        );
      },
    },
    {
      field: "certificateUrl",
      headerName: "Certificate URL",
      width: 200,
      renderCell: (params) => {
        return (
          <>
            {params.row.certificate && (
              <div
                className="cert-link"
                onClick={() => {
                  window.open(params.row.certificateUrl);
                }}
              >
                Click here
              </div>
            )}
          </>
        );
      },
    },
    {
      field: "feedback",
      headerName: "Feedback",
      width: 200,
      renderCell: (params) => {
        return (
          <>
            {params.row.Feedback && (
              <div
                className="cert-link"
                onClick={() => {
                  setFeedback({ ...params.row.Feedback });
                  setFeedbackDialog(true);
                }}
              >
                Click here
              </div>
            )}
          </>
        );
      },
    },
  ];
  return (
    <>
      <div className="webinar-container light-background">
        <Grid container spacing={3}>
          <Grid item sm={6} xs={12}>
            <div className="webinar-card">
              <div className="webinar-heading">Event Overview</div>
              <Grid container id="webinar-card-row">
                <Grid item sm={6} xs={12} id="webinar-card-row-title">
                  Name
                </Grid>
                <Grid item sm={6} xs={12} id="webinar-card-row-content">
                  {eventDet.name}
                </Grid>
              </Grid>
              <Grid container id="webinar-card-row">
                <Grid item sm={6} xs={12} id="webinar-card-row-title">
                  Domain
                </Grid>
                <Grid item sm={6} xs={12} id="webinar-card-row-content">
                  {eventDet.domain}
                </Grid>
              </Grid>
              <Grid container id="webinar-card-row">
                <Grid item sm={6} xs={12} id="webinar-card-row-title">
                  Event Date and Time
                </Grid>
                <Grid item sm={6} xs={12} id="webinar-card-row-content">
                  {moment(eventDet.when).format("LLL")}
                </Grid>
              </Grid>
              <Grid container id="webinar-card-row">
                <Grid item sm={6} xs={12} id="webinar-card-row-title">
                  Registration Starts
                </Grid>
                <Grid item sm={6} xs={12} id="webinar-card-row-content">
                  {moment(eventDet.regStart).format("LLL")}
                </Grid>
              </Grid>
              <Grid container id="webinar-card-row">
                <Grid item sm={6} xs={12} id="webinar-card-row-title">
                  Registration closes
                </Grid>
                <Grid item sm={6} xs={12} id="webinar-card-row-content">
                  {moment(eventDet.regEnd).format("LLL")}
                </Grid>
              </Grid>
              <Grid container id="webinar-card-row">
                <Grid item sm={12} xs={12} id="webinar-card-row-title">
                  Description
                </Grid>
                <Grid item sm={12} xs={12} id="webinar-card-row-content">
                  {eventDet.desc}
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item sm={6} xs={12}>
            <div className="webinar-card center">
              <div className="webinar-reg-num num">{eventReg.length}</div>
              <div className="webinar-reg-num text">Registrations</div>
            </div>
          </Grid>
        </Grid>
        {eventReg.length === 0 && (
          <Grid container spacing={3}>
            <Grid item sm={12} xs={12}>
              <div className="webinar-card">
                <div className="no-data-text">No data available</div>
              </div>
            </Grid>
          </Grid>
        )}
        {eventReg.length !== 0 && (
          <>
            <Grid container spacing={3}>
              <Grid item sm={6} xs={12}>
                <div className="webinar-card">
                  <div className="webinar-heading">Year-wise distrubution</div>
                  <Doughnut
                    className="webinar-chart year"
                    data={{
                      labels: regYear.map(({ year }) => year),
                      datasets: [
                        {
                          label: "Number of registration per year",
                          data: regYear.map(({ number }) => number),
                          backgroundColor: Array(regYear.length)
                            .fill(null)
                            .map(() => getRandomColor()),
                        },
                      ],
                    }}
                    options={{
                      radius: "80%",
                    }}
                  />
                </div>
              </Grid>
              <Grid item sm={6} xs={12}>
                <div className="webinar-card">
                  <div className="webinar-heading">
                    Source-wise distrubution
                  </div>
                  <Doughnut
                    className="webinar-chart source"
                    data={{
                      labels: sourceList,
                      datasets: [
                        {
                          label: "Source List",
                          data: regSource.map(({ number }) => number),
                          backgroundColor: Array(regYear.length)
                            .fill(null)
                            .map(() => getRandomColor()),
                        },
                      ],
                    }}
                    options={{
                      radius: "80%",
                    }}
                  />
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <div className="webinar-card">
                  <div className="webinar-heading">
                    Prior Knowledge-wise distrubution
                  </div>
                  <Doughnut
                    data={{
                      labels: prior.map(({ type }) => type),
                      datasets: [
                        {
                          label: "# of prior knowledge",
                          data: prior.map(({ number }) => number),
                          backgroundColor: Array(prior.length)
                            .fill(null)
                            .map(() => getRandomColor()),
                        },
                      ],
                    }}
                    options={{
                      radius: "80%",
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div className="webinar-card">
                  <div className="webinar-heading">
                    Branch-wise distrubution
                  </div>
                  <Doughnut
                    data={{
                      labels: branch.map(({ branch }) => branch),
                      datasets: [
                        {
                          label: "# of branch",
                          data: branch.map(({ number }) => number),
                          backgroundColor: Array(branch.length)
                            .fill(null)
                            .map(() => getRandomColor()),
                        },
                      ],
                    }}
                    options={{
                      radius: "80%",
                    }}
                  />
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <div
                  className="webinar-card"
                  style={{ height: "calc(100vh - 100px)" }}
                >
                  <div className="webinar-heading">
                    <div className="webinar-heading-text">Registration</div>
                    <div className="webinar-heading-actions">
                      <div>
                        <Button
                          onClick={handleClick}
                          variant="contained"
                          disableElevation
                          aria-haspopup="true"
                          aria-controls="action-menu"
                          aria-expanded={menuOpen ? "true" : undefined}
                          style={{
                            textTransform: "none",
                          }}
                          endIcon={<KeyboardArrowDown />}
                        >
                          Actions
                        </Button>
                        <Menu
                          id="action-menu"
                          aria-haspopup="true"
                          onClick={handleMenu}
                          open={menuOpen}
                          onClose={handleMenu}
                          anchorEl={anchorEl}
                          MenuListProps={{
                            "aria-labelledby": "basic-button",
                          }}
                        >
                          <MenuItem
                            onClick={handleGenCertWebinar}
                            id="menu-item-reg"
                          >
                            Generate Certificates
                          </MenuItem>
                          <MenuItem
                            onClick={handleCertEmailing}
                            id="menu-item-reg"
                          >
                            Certificate Emailing
                          </MenuItem>
                        </Menu>
                      </div>
                      <div>
                        <Button
                          onClick={() => {
                            setOpenCertDialog(true);
                          }}
                          variant="outlined"
                          style={{
                            textTransform: "none",
                          }}
                        >
                          Certificate Emails
                        </Button>
                      </div>
                      <div>
                        <Button
                          onClick={() => {
                            setOpenEmailDialog(true);
                          }}
                          variant="outlined"
                          style={{
                            textTransform: "none",
                          }}
                        >
                          Get Emails
                        </Button>
                      </div>
                    </div>
                  </div>
                  <DataGrid
                    rows={eventReg}
                    getRowId={(row) => row._id}
                    loading={eventReg.length === 0}
                    columns={columns}
                    disableSelectionOnClickx
                    disableSelectionOnClick
                    pagination
                  />
                </div>
              </Grid>
            </Grid>
          </>
        )}
      </div>
      <Dialog
        open={openEmailDialog}
        onClose={onCancel}
        maxWidth={"sm"}
        fullWidth
      >
        <DialogTitle>Email List</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <div>{emails.map((each) => `${each},\n`)}</div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={onCancel}
            style={{
              fontSize: "1rem",
              fontWeight: "600",
              color: "#fff",
              backgroundColor: "#06d6a0",
            }}
            variant="text"
            disableElevation
          >
            Got it!
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openCertDialog}
        onClose={onCertCancel}
        maxWidth={"sm"}
        fullWidth
      >
        <DialogTitle>Certificate Email List</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <div>
              {eventReg
                .filter((each) => each.attendance === true)
                .map((each) => each.email).length === 0
                ? "No Email to display"
                : eventReg
                    .filter((each) => each.attendance === true)
                    .map((each) => `${each.email}\n`)}
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={onCertCancel}
            style={{
              fontSize: "1rem",
              fontWeight: "600",
              color: "#fff",
              backgroundColor: "#06d6a0",
            }}
            variant="text"
            disableElevation
          >
            Got it!
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openFeedbackDialog}
        onClose={onFeedbackCancel}
        maxWidth={"sm"}
        fullWidth
      >
        <DialogTitle>Feedback</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Grid container id="webinar-card-row">
              <Grid item sm={6} xs={12} id="webinar-card-row-title">
                Rating
              </Grid>
              <Grid item sm={6} xs={12} id="webinar-card-row-content">
                <Rating defaultValue={feedback.experience} readOnly />
              </Grid>
            </Grid>
            <Grid container id="webinar-card-row">
              <Grid item sm={6} xs={12} id="webinar-card-row-title">
                Did the candidate learnt something new?
              </Grid>
              <Grid item sm={6} xs={12} id="webinar-card-row-content">
                {feedback.newLesson ? "Yes" : "No"}
              </Grid>
            </Grid>
            <Grid container id="webinar-card-row">
              <Grid item sm={6} xs={12} id="webinar-card-row-title">
                Did the candidate want similar session on this topic?
              </Grid>
              <Grid item sm={6} xs={12} id="webinar-card-row-content">
                {feedback.similarSession ? "Yes" : "No"}
              </Grid>
            </Grid>
            <Grid container id="webinar-card-row">
              <Grid item sm={6} xs={12} id="webinar-card-row-title">
                Suggestions
              </Grid>
              <Grid item sm={6} xs={12} id="webinar-card-row-content">
                {feedback.suggestions}
              </Grid>
            </Grid>
            <Grid container id="webinar-card-row">
              <Grid item sm={6} xs={12} id="webinar-card-row-title">
                Interested topics of the candidate
              </Grid>
              <Grid item sm={6} xs={12} id="webinar-card-row-content">
                {feedback.upcomingTopics &&
                  (feedback.upcomingTopics.length === 0
                    ? "No selection"
                    : feedback.upcomingTopics.map((each, index) =>
                        feedback.upcomingTopics.length - 1 === index
                          ? `${each}`
                          : `${each}, `
                      ))}
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={onFeedbackCancel}
            style={{
              fontSize: "1rem",
              fontWeight: "600",
              color: "#fff",
            }}
            variant="text"
            disableElevation
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default withRouter(Webinar);
