import {
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  FormHelperText,
} from "@material-ui/core";
import React from "react";
import { Controller } from "react-hook-form";
import "../input/input.css";
function SelectBox({
  name,
  label,
  control,
  options,
  defaultValue = "",
  rules = {},
  errors = {},
}) {
  return (
    <div className="input-container">
      <FormControl>
        <InputLabel id="select-label" style={{ fontSize: "1.2rem" }}>
          {label}
        </InputLabel>
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue}
          rules={rules}
          render={({ field }) => (
            <Select id="select-label" className="input-textfield" {...field}>
              {options.map((eachOption, index) => (
                <MenuItem value={eachOption} key={index}>
                  {eachOption}
                </MenuItem>
              ))}
            </Select>
          )}
        />
        {errors[name] && (
          <FormHelperText error={errors[name] ? true : false}>
            {errors[name].message}
          </FormHelperText>
        )}
      </FormControl>
    </div>
  );
}

export default SelectBox;
