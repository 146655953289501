import React, { useState } from "react";
import Switch from "react-switch";
import { useController } from "react-hook-form";
import "./sliderSwitch.css";

function SliderSwitch({
  name,
  label,
  control,
  defaultValue = "",
  errors = {},
  rules = {},
  ...extras
}) {
  const { field } = useController({
    name,
    control,
    rules,
    defaultValue: defaultValue !== "" ? defaultValue : false,
  });
  const [value, setValue] = useState(
    defaultValue !== "" ? defaultValue : false
  );
  return (
    <div className="switch-container">
      <div className="switch-label">{label}</div>
      <div className="switch-slider">
        <Switch
          checked={value}
          onChange={(presentValue) => {
            setValue(presentValue);
            field.onChange(presentValue);
          }}
          checkedIcon={false}
          uncheckedIcon={false}
          {...extras}
        />
      </div>
    </div>
  );
}

export default SliderSwitch;
