import "./sidebar.css";
import {
  LineStyle,
  PersonAdd,
  PeopleAltRounded,
  Timeline,
  PermIdentity,
  Storefront,
  MailOutline,
  DynamicFeed,
  ChatBubbleOutline,
  WorkOutline,
  Report,
} from "@material-ui/icons";
import { Link } from "react-router-dom";

export default function Sidebar() {
  let isAdmin = false;
  if (localStorage.getItem("ud")) {
    isAdmin = JSON.parse(localStorage.getItem("ud")).uadmin;
  }
  return (
    <div className="sidebar">
      <div className="sidebarWrapper">
        <div className="sidebarMenu">
          <h3 className="sidebarTitle">Dashboard</h3>
          <ul className="sidebarList">
            <Link to="/" className="link">
              <li className="sidebarListItem">
                <LineStyle className="sidebarIcon" />
                Home
              </li>
            </Link>
          </ul>
        </div>
        <div className="sidebarMenu">
          <h3 className="sidebarTitle">Quick Menu</h3>
          <ul className="sidebarList">
            <Link to="/team" className="link">
              <li className="sidebarListItem">
                <PermIdentity className="sidebarIcon" />
                Team
              </li>
            </Link>
            <Link to="/events" className="link">
              <li className="sidebarListItem">
                <Storefront className="sidebarIcon" />
                Events/Sessions
              </li>
            </Link>
          </ul>
        </div>
        <div className="sidebarMenu">
          <h3 className="sidebarTitle">Team</h3>
          <ul className="sidebarList">
            {isAdmin && (
              <Link to="/team/new" className="link">
                <li className="sidebarListItem">
                  <PersonAdd className="sidebarIcon" />
                  Add member
                </li>
              </Link>
            )}
            <Link to="/team" className="link">
              <li className="sidebarListItem">
                <PeopleAltRounded className="sidebarIcon" />
                Members
              </li>
            </Link>
          </ul>
        </div>
        <div className="sidebarMenu">
          <h3 className="sidebarTitle">Event/Webinar</h3>
          <ul className="sidebarList">
            {isAdmin && (
              <Link to="/event/new" className="link">
                <li className="sidebarListItem">
                  <PersonAdd className="sidebarIcon" />
                  Add Event
                </li>
              </Link>
            )}
            <Link to="/events" className="link">
              <li className="sidebarListItem">
                <PeopleAltRounded className="sidebarIcon" />
                Events
              </li>
            </Link>
          </ul>
        </div>
        
        
      </div>
    </div>
  );
}
