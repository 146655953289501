import "./home.css";
import logo from "../../assets/elabs_logo.png";

export default function Home() {
  return (
    <div className="home">
      <div className="wrapper">
        <div className="one">
          <div className="heading">
            <h6 className="h6">Admin Console</h6>
            <h1 className="h1">E LABS</h1> <br />
            <button>Main Website</button>
          </div>
        </div>
        <div className="two">
          <div className="overflow">
            <img
              src={logo}
              alt="logo"
              className="home-logo"
              style={{
                width: "15rem",
                height: "15rem",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
