import "./login.css";
import React, { useState, forwardRef, useEffect } from "react";
import loginImage from "../../assets/login.svg";
import Input from "../../components/input/Input";
import { useForm } from "react-hook-form";
import { Button, CircularProgress, Snackbar } from "@material-ui/core";
import OtpField from "../../components/otpInput/OtpField";
import MuiAlert from "@material-ui/lab/Alert";

function Login() {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [otpSent, setOtpSent] = useState(false);
  const [progress, setProgress] = useState(false);
  const [otpValid, setOtpValid] = useState(false);
  const [alertBar, setAlertBar] = useState({
    open: false,
    success: false,
    message: "",
  });

  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertBar((cur) => ({ ...cur, open: false }));
  };

  useEffect(() => {
    if (otpValid === false) {
      setOtpSent(false);
    }
  }, [otpValid]);

  const onSubmit = async (data) => {
    console.log(data);
    if (!otpSent) {
      setProgress(true);
      const apiUrl = `${process.env.REACT_APP_SERVER_URL}/auth/admin/login`;
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(data),
      });
      const returnedData = await response.json();
      if (response.status === 201) {
        setAlertBar({
          open: true,
          success: true,
          message: "OTP sent to email. Valid for 2 mins",
        });
        setOtpSent(true);
        setOtpValid(true);
        setProgress(false);
      } else {
        setAlertBar({
          open: true,
          success: false,
          message: `${returnedData.error}`,
        });
        setProgress(false);
      }
    }
    if (otpSent) {
      setProgress(true);
      const apiUrl = `${process.env.REACT_APP_SERVER_URL}/auth/admin/verify`;
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (response.status === 200) {
        const returnedData = await response.json();
        let authkey = "";
        response.headers.forEach((val, key) => {
          if (key === "auth-token") {
            authkey = val;
          }
        });
        const ud = {
          utoken: authkey,
          uid: returnedData.data._id,
          uname: returnedData.data.name,
          uadmin: returnedData.data.isAdmin,
          uphoto: returnedData.data.photo,
          udomain: returnedData.data.domain,
        };
        localStorage.setItem("ud", JSON.stringify(ud));
        setProgress(false);
        window.location.assign("/");
      } else {
        setAlertBar({
          open: true,
          success: false,
          message: `Invalid OTP`,
        });
        setProgress(false);
      }
    }
  };

  return (
    <>
      <div className="login-wrapper">
        <div className="left-section">
          <img alt="Front img" src={loginImage} className="front-img" />
        </div>
        <div className="right-section">
          <div className="login-form">
            <div className="big auth-text">Let's go...</div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Input
                name="email"
                label="Email"
                control={control}
                errors={errors}
                disabled={otpSent}
                variant="outlined"
                rules={{
                  required: "Email is required",
                  pattern: {
                    value: /^[0-9]{7,8}@kiit.ac.in$/,
                    message: "Enter a KIIT email",
                  },
                }}
              />
              {otpSent && (
                <OtpField
                  name="otp"
                  control={control}
                  errors={errors}
                  otpValid={otpValid}
                  setOtpValid={setOtpValid}
                  rules={{ required: "Enter the OTP" }}
                />
              )}
              <div className="button-container">
                <Button
                  variant="contained"
                  disableElevation
                  type="submit"
                  className="login-button"
                  disabled={progress}
                  style={{
                    backgroundColor: "#4169E1",
                    color: "white",
                    fontWeight: 400,
                    fontSize: "1.1rem",
                    width: "30vw",
                    marginTop: "1.4rem",
                    textTransform: "none",
                  }}
                >
                  {progress && <CircularProgress size="1.8rem" color="#fff" />}
                  {!progress && !otpSent && "Login"}
                  {!progress && otpSent && "Verify"}
                </Button>
                {/* <Button
                  variant="text"  
                  disableElevation
                  type="submit"
                  className="login-button"
                  style={{
                    color: "#4169E1",
                    fontWeight: 600,
                    fontSize: "1.1rem",
                    width: "30vw",
                    marginTop: "1rem",
                    textTransform: "none",
                  }}
                >
                  {"Apply for admin"}
                </Button> */}
              </div>
            </form>
          </div>
        </div>
      </div>
      <Snackbar
        open={alertBar.open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          severity={alertBar.success ? "success" : "error"}
          onClose={handleClose}
        >
          {alertBar.message}
        </Alert>
      </Snackbar>
    </>
  );
}

export default Login;
