const eventDomainList = [
  "Web Development",
  "App Development",
  "Machine Learning",
  "Cloud Computing",
  "Java",
  "Networking",
  "Internet of Things",
  "Embedded Systems",
];
export default eventDomainList;
