import React from "react";
import MomentUtils from "@date-io/moment";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Controller } from "react-hook-form";
import "../input/input.css";
function DateTime({
  label,
  name,
  control,
  defaultValue = null,
  format = "LLL",
  errors = {},
  rules = {},
}) {
  return (
    <div className="input-container">
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Controller
          name={name}
          control={control}
          rules={rules}
          defaultValue={defaultValue}
          render={({ field }) => (
            <DateTimePicker
              autoOk={true}
              disablePast
              label={label}
              placeholder={label}
              format={format}
              inputProps={{
                id: "input-form",
              }}
              error={errors[name] ? true : false}
              helperText={errors[name] && errors[name].message}
              className="input-textfield"
              {...field}
            />
          )}
        />
      </MuiPickersUtilsProvider>
    </div>
  );
}

export default DateTime;
