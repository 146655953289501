import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@material-ui/core";
function ConfirmationDialog({
  status,
  title,
  message,
  onCancel,
  onSuccess,
  mainButtonText,
  type,
  color = "green",
}) {
  return (
    <Dialog open={status} onClose={onCancel}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onCancel}
          style={{ fontSize: "1rem", fontWeight: "600" }}
        >
          Cancel
        </Button>
        <Button
          onClick={onSuccess}
          style={{
            color: "white",
            fontWeight: 600,
            backgroundColor: type === "delete" ? "red" : color,
            fontSize: "1rem",
          }}
          autoFocus
        >
          {mainButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmationDialog;
